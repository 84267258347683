/*
// Import only what you need from Bulma
@import '../node_modules/bulma/sass/utilities/_all.sass';
@import '../node_modules/bulma/sass/base/_all.sass';
@import '../node_modules/bulma/sass/elements/button.sass';
@import '../node_modules/bulma/sass/elements/container.sass';
@import '../node_modules/bulma/sass/elements/form.sass';
@import '../node_modules/bulma/sass/elements/title.sass';
@import '../node_modules/bulma/sass/components/navbar.sass';
@import '../node_modules/bulma/sass/layout/hero.sass';
@import '../node_modules/bulma/sass/layout/section.sass';
*/

@import "../../node_modules/bulma/bulma.sass";

$color01: #fff;
$color02: #000;
$color03: #7d172b;
$color04: #cc9900;
$color05: #f8f7f6;
$color06: #e5e5e5;
$color07: #b85e00;
$color08: #c93a55;
$color09: #bf4f24;
$color10: #b85e00;
$color11: #333333;
$color12: #e58725;
$color13: #808080;
$color14: #33417f;
$color15: #c54242;
$color16: #bc5515;
$color17: #666666;
$color18: #f1efed;
$color19: #999999;
$color20: #e80c39;
$color21: #1c8239;
$color22: #bfbfbf;
$color23: #8b5217;
$color24: #6f2030;

* {
  box-sizing: border-box;
  overscroll-behavior-y: none;
}

body,
button,
input,
select,
textarea {
  font-family: "Noto Sans JP", sans-serif;
}

body {
  background: $color05;

  &.prevent-scrolling {
    overflow: hidden;
    max-height: 100vh;
  }
}
//support font for IE
@font-face {
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 400;
  src: local("Noto Sans"), local("NotoSans"),
    url(https://fonts.gstatic.com/s/notosans/v9/o-0IIpQlx3QUlC5A4PNr5TRG.woff) format("woff");
}

.App {
  color: $color11;
  overflow-x: hidden;

  @media screen and (max-width: 768px) {
    font-size: 13px;
  }
}

.container {
  @media screen and (max-width: 768px) {
    // Remove vertical scrolling on mobile
    .columns {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  @media screen and (min-width: 1024px) {
    max-width: 960px;
  }

  @media screen and (min-width: 1216px) {
    max-width: 960px;
  }

  @media screen and (min-width: 1408px) {
    max-width: 960px;
  }
}

.is-bg-lightgrey {
  background-color: #efedea;
}

.is-bg-lightgrey-2 {
  background-color: $color18;
}

.is-bg-white {
  background-color: $color01;
}

.text-red {
  color: $color03;
}

.text-pink {
  color: $color08;
}

button,
a {
  &:hover,
  &:focus {
    outline: none;
  }
}
#simulation-chart {
  .toshin-chart {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  .discrete-color-legend {
    top: auto;
    bottom: 10px !important;
    stroke: #fff;
  }

  .bottom-chart-box {
    top: auto;
    bottom: 50px !important;
    background: rgb(204, 204, 204);
    opacity: 0.5;
    height: 50px;

    @media screen and (max-width: 768px) {
      bottom: 60px !important;
      height: 40px;
    }

    @media screen and (max-width: 320px) {
      bottom: 65px !important;
      height: 35px;
    }
  }

  .rv-xy-plot__axis__line {
    stroke: transparent;
  }
  .rv-xy-plot__axis__tick__line {
    stroke: #fff;
  }
  .rv-crosshair__line {
    background: #cccccc;
  }
}

.page-section__body {
  &.mobile-padding {
    @media screen and (max-width: 768px) {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}

.mobile-mb-0.columns {
  @media screen and (max-width: 768px) {
    margin-bottom: 0;
  }
}
.first-kabu-question,
.first-toshin-question,
.question-survey-mobile,
.question-survey {
  &.animated {
    animation-duration: 600ms;
  }
}

.toshin-chart {
  .rv-discrete-color-legend {
    &.horizontal {
      position: absolute;
      top: 0;
      margin: 0 auto;
      left: 0;
      right: 0;
      width: fit-content;
    }
    &-item {
      &.horizontal {
        .rv-discrete-color-legend-item__title {
          font-size: 12px;
          margin-left: 10px;
          display: inline;
          vertical-align: middle;
        }
      }
      &__color {
        width: 25px;
        &__path {
          //d: path("M 0, 1 L 25, 1")
        }
      }
    }
  }
}

//.toshin-chart .rv-discrete-color-legend-item__color {
//  width: 25px;
//}

//.toshin-chart .rv-discrete-color-legend-item__color__path {
//
//}

.historical-chart .balloon {
  font-size: 10px;
  background: #324081;
  padding: 4px 4px;
  color: #fff;
  border-radius: 4px;
}

.historical-chart .lengends-toshin {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: 0;
  display: flex;
  justify-content: center;
  padding: 5px 0px;
  border: 1px solid #000;
}
.historical-chart .lengends-toshin .rv-discrete-color-legend-item__title::before {
  content: ":";
}

.historical-chart .rv-discrete-color-legend.horizontal.lengends-toshin .rv-discrete-color-legend-item {
  padding: 0px 5px;
}

.historical-chart .rv-discrete-color-legend.horizontal.lengends-toshin .rv-discrete-color-legend-item__title {
  display: inline;
  margin-left: 5px;
}

.historical-chart .crosshair-history {
  background: #5b6799;
  padding: 5px;
  width: 180px;
}

.historical-chart .crosshair-history p:first-child {
  margin-bottom: 0px;
}

.alt-y-label.rv-xy-plot__axis__title text {
  fill: #333333;
  font-weight: bold;
}

@media screen and (max-width: 768px) {
  .historical-chart .lengends-toshin {
    bottom: 10px;
  }
}

@media screen and (max-width: 767px) {
  .historical-chart .lengends-toshin {
    bottom: 10px;
    padding: 5px 0px;
    font-size: 10px;
  }
  .historical-chart .rv-discrete-color-legend.horizontal.lengends-toshin .rv-discrete-color-legend-item {
    padding: 0px 3px;
  }
  .historical-chart .rv-discrete-color-legend.horizontal.lengends-toshin .rv-discrete-color-legend-item__title {
    margin-left: 3px;
    font-size: 9px;
  }

  .historical-chart .crosshair-history {
    padding: 4px;
    width: 130px;
    font-size: 8px;
  }
  .historical-chart .crosshair-history p:first-child {
    margin-bottom: 0px !important;
  }
}

.collapse-div {
  display: block;
  border-radius: 15px;
  margin: 10px;
  text-align: center;
  transition-duration: .15s;
  transition-timing-function: ease-in-out;
  transition: transform 1000ms ease;
  -moz-transition: -moz-transform 1000ms ease;
  -ms-transition: -ms-transform 1000ms ease;
  -o-transition: -o-transform 1000ms ease;
  -webkit-transition: -webkit-transform 1000ms ease;
  &:hover {
    background-color: rgba(0,0,0,.04);
  }
  &:after {
    background-color: rgba(0,0,0,.04);
    transition-timing-function: ease-in-out;
    transition-duration: .15s;
  }
}
.rotateOver {
  transform: rotate(-180deg);
  -moz-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  -o-transform: rotate(-180deg);
  -webkit-transform: rotate(-180deg);
  transition: transform 550ms ease;
  -moz-transition: -moz-transform 550ms ease;
  -ms-transition: -ms-transform 550ms ease;
  -o-transition: -o-transform 550ms ease;
  -webkit-transition: -webkit-transform 550ms ease;
}
.rotateOut {
  transform: rotate(-360deg);
  -moz-transform: rotate(-360deg);
  -ms-transform: rotate(-360deg);
  -o-transform: rotate(-360deg);
  -webkit-transform: rotate(-360deg);
  transition: transform 550ms ease;
  -moz-transition: -moz-transform 550ms ease;
  -ms-transition: -ms-transform 550ms ease;
  -o-transition: -o-transform 550ms ease;
  -webkit-transition: -webkit-transform 550ms ease;
}
